<template>
  <div class="base-buttons">
    <div
      class="mr-1"
      :class="{ 'hover-cursor': cValue > 1 }"
      @click="emitPage(cValue - 1)"
    >
      <a-icon type="left" />
    </div>

    <div
      v-for="item in totalCount"
      :class="[
        'ba-pagination-number',
        { active: cValue === +item, 'ba-pagination-number-disabled': loading },
      ]"
      :key="item"
      @click="emitPage(item)"
    >
      {{ item }}
    </div>

    <div
      class="ml-1"
      :class="{ 'hover-cursor': cValue !== totalCount }"
      @click="emitPage(cValue + 1)"
    >
      <a-icon type="right" />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    loading: {
      type: Boolean,
    },
    total: {
      // total count
      type: [Number, String],
      default: () => 0,
    },
    limit: {
      // per page
      type: Number,
      default: () => 10,
    },
    btnCount: {
      // size of slice
      type: [Number, String],
      default: () => 3,
    },

    value: {
      // page number
      type: [Number, String],
      default: () => -1,
    },
    navigate: {
      // use $router.push ?
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    totalCount() {
      return Math.ceil((+this.total || 0) / (+this.limit || 10));
    },

    // pagesSlice() {
    //   const btnCount = parseInt(this.btnCount)
    //   const pageIndexNumber = parseInt(this.value ? this.value - 1 : 0)
    //   const buttonsCount = Array.from(
    //     { length: this.totalCount },
    //     (_, x) => x + 1
    //   )

    //   const offset = buttonsCount.length - this.value

    //   if (offset < btnCount - 1) {
    //     // console.log('--', offset, btnCount);
    //     return buttonsCount
    //       .slice(buttonsCount.length - btnCount)
    //       .slice(0, btnCount)
    //   }

    //   if (pageIndexNumber > 0) {
    //     // console.log(2);
    //     const sliceOffset = this.value + btnCount
    //     return buttonsCount
    //       .slice(pageIndexNumber - 1, sliceOffset)
    //       .slice(0, btnCount)
    //   }

    //   // console.log(3);

    //   return buttonsCount.slice(pageIndexNumber, pageIndexNumber + btnCount)
    //   // .splice( || 1, 0);
    // },

    cValue() {
      return +this.value || 0;
    },
  },

  methods: {
    emitPage(pageNumber) {
      if (
        pageNumber <= 0 ||
        pageNumber > this.totalCount ||
        pageNumber == this.value ||
        this.totalCount == 1
      ) {
        return;
      }

      this.$emit('change', pageNumber);

      if (this.navigate) {
        this.$router.push({
          query: {
            ...this.$router.query,
            page: pageNumber,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.base-buttons {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}
.base-buttons i {
  font-size: 20px;
  margin-top: 5px;
  /* background-color: beige; */
  color: #1890ffff;
  border: 1px solid #1890ffff;
  padding: 5px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}
.base-buttons i:hover {
  background-color: #1890ffff;
  color: white;
}
.ba-pagination-number {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  color: #1890ffff;
  background-color: #fff;
  border: 2px solid #1890ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
}
.ba-pagination-number.active {
  cursor: default;
  background-color: #1890ffff;
  color: #fff;
}
</style>
