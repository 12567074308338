import productAction from './productAction';
import productGetter from './productGetter';
import productMutation from './productMutations';
export default {
  namespaced: true,
  state() {
    return {
      categoryList: [],
      oneCategory: {},
    };
  },
  getters: productGetter,
  mutations: productMutation,
  actions: productAction,
};
