import Vue from 'vue';
import App from './App.vue';
import Ant from 'ant-design-vue';
import router from './routes/index.js';
import store from './store/index.js';
import 'ant-design-vue/dist/antd.css';

//Global Components
import TheMainView from './components/BaseComponents/TheMainView.vue';
import Modal from './components/ui/Modal.vue';

Vue.config.productionTip = false;

Vue.use(Ant);
Vue.component('the-main-view', TheMainView);
Vue.component('modal', Modal);

new Vue({
  store,
  router: router,
  render: (h) => h(App),
}).$mount('#app');
