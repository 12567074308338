import request from '../../../api/fetchConfig';
export default {
  loadAllCategory() {
    return request('products/category/', 'GET');
  },

  getChildCategory() {
    return request('products/category/?children=true', 'GET');
  },

  filterCategory(_, value) {
    return request(`products/category/?search=${value}`, 'GET');
  },

  postCategory(_, data) {
    return request('products/category/', 'POST', data);
  },

  getOneCategory(_, dataId) {
    return request(`products/category/${dataId}/`)
      .then((res) => {
        // context.commit('getOneCategory', res);
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  patchOneCategory(_, obj) {
    return request(`products/category/${obj.id}/`, 'PATCH', obj.data);
  },

  deleteOneCategory(_, id) {
    return request(`products/category/${id}/`, 'DELETE');
  },
};
