import Vue from 'vue';
import Vuex from 'vuex';
import categoryModule from './modules/category/categoryModule';
import productModule from './modules/product/productModule';
import silderModule from './modules/slider/sliderModule';
import documentModule from './modules/document/documentModule';
import partnerModule from './modules/partner/partenerModule';
import contactModule from './modules/contact/contactModule'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    category: categoryModule,
    product: productModule,
    slider: silderModule,
    document: documentModule,
    partner: partnerModule,
    contact: contactModule,
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {},
});
