import rootUrl from '../config/url.js';

const request = (url, method, body) => {
  const TOKEN_PREFIX = 'Bearer';
  const TOKEN = sessionStorage.getItem('token');

  const promise = new Promise((resolve, reject) => {
    fetch(`${rootUrl}/${url}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: TOKEN_PREFIX + ' ' + TOKEN,
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.status === 400) {
          throw new Error();
        }
        if (res.status === 404) {
          throw new Error('Xatolik! ');
        }
        if (res.status === 401) {
          throw new Error('Bunday akkount mavjud emas');
        }
        if (res.status == 204) {
          return 'ochirildi';
        } else {
          return res.json();
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
};

export const formDataRequest = (url, method, data) => {
  const TOKEN_PREFIX = 'Bearer';
  const TOKEN = sessionStorage.getItem('token');

  const promise = new Promise((resolve, reject) => {
    const formData = new FormData();
    for (let i in data) {
      formData.append(i, data[i]);
    }

    fetch(`${rootUrl}/${url}`, {
      method: method,
      headers: {
        Authorization: TOKEN_PREFIX + ' ' + TOKEN,
      },
      body: formData,
    })
      .then((res) => {
        if (res.status === 400) {
          throw new Error('Xatolik! ');
        }
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

export default request;
