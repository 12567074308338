import request, { formDataRequest } from '../../../api/fetchConfig';
export default {
  postDocument(_, data) {
    return formDataRequest('documents/documents/', 'POST', data);
  },
  getAllDocument() {
    return request('documents/documents/', 'GET');
  },

  getOneDocs(_, id) {
    return request(`documents/documents/${id}/`, 'GET');
  },
  patchDocument(_, data) {
    return formDataRequest(
      `documents/documents/${data.id}/`,
      'PATCH',
      data.obj
    );
  },
  deleteOneDocument(_, id) {
    return request(`documents/documents/${id}/`, 'DELETE');
  },
};
