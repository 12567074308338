import Product from '../components/Products/Product.vue';

export default [
  {
    path: '/',
    component: () => import('../../layouts/Default.vue'),
    children: [
      {
        path: '/category',
        meta: {
          auth: true,
        },
        name: 'Categoriya',
        component: () => import('../views/category/categoryForm.vue'),
        hidden: true,
      },
      {
        path: '/product',
        meta: {
          auth: true,
        },
        component: Product,
      },
      {
        path: '/new-category',
        meta: {
          auth: true,
        },
        name: 'NewCategory',
        component: () => import('../views/category/categoryInputs.vue'),
      },
      {
        path: '/edit-category/:id',
        meta: {
          auth: true,
        },
        props: true,
        name: 'EditCategory',
        component: () => import('../views/category/categoryEditForm.vue'),
      },
      {
        path: '/new-product',
        meta: {
          auth: true,
        },
        name: 'NewProduct',
        component: () => import('../views/product/createProduct.vue'),
      },
      {
        path: '/edit-product/:productId',
        meta: {
          auth: true,
        },
        props: true,
        name: 'EditProduct',
        component: () => import('../views/product/editProduct.vue'),
      },

      {
        path: '/new-slider',
        meta: {
          auth: true,
        },
        name: 'newSlider',
        component: () => import('../views/slider/sliderCreate.vue'),
      },
      {
        path: '/edit-slider/:sliderId',
        meta: {
          auth: true,
        },
        props: true,
        name: 'editSlider',
        component: () => import('../views/slider/sliderEdit.vue'),
      },
      {
        path: '/slider',
        meta: {
          auth: true,
        },
        name: 'Slider',
        component: () => import('../views/slider/sliderView.vue'),
      },
      {
        path: '/document',
        meta: {
          auth: true,
        },
        name: 'Document',
        component: () => import('../views/document/documentView.vue'),
      },
      {
        path: '/new-document',
        meta: {
          auth: true,
        },
        name: 'newDocument',
        component: () => import('../views/document/docsCreate.vue'),
      },
      {
        path: '/edit-document/:docsId',
        meta: {
          auth: true,
        },
        props: true,
        name: 'editDocument',
        component: () => import('../views/document/docsEdit.vue'),
      },
      {
        path: '/edit-partner/:partnerId',
        meta: {
          auth: true,
        },
        props: true,
        name: 'editPartner',
        component: () => import('../views/partner/partnerEdit.vue'),
      },
      {
        path: '/new-partner',
        meta: {
          auth: true,
        },
        name: 'newPartner',
        component: () => import('../views/partner/partnerCreate.vue'),
      },
      {
        path: '/partner',
        meta: {
          auth: true,
        },
        name: 'Partner',
        component: () => import('../views/partner/partnerView.vue'),
      },
      {
        path: '/edit-company',
        meta: {
          auth: true,
        },
        name: 'Company',
        component: () => import('../views/company/companyEdit.vue'),
      },
      {
        path: '/edit-socialContact',
        meta: {
          auth: true,
        },
        name: 'SocialContact',
        component: () => import('../views/contacts/editSocialContact.vue'),
      },
      {
        path: '/contacts',
        meta: {
          auth: true,
        },
        name: 'Contact',
        component: () => import('../views/phoneContact/contactView.vue'),
      },
      {
        path: '/new-contacts',
        meta: {
          auth: true,
        },
        name: 'newContact',
        component: () => import('../views/phoneContact/contactCreate.vue'),
      },
      {
        path: '/edit-contacts/:contactId',
        meta: {
          auth: true,
        },
        props: true,
        name: 'editContact',
        component: () => import('../views/phoneContact/contactEdit.vue'),
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../../layouts/Login.vue'),
  },

  {
    path: '**',
    component: () => import('../../layouts/NotFound.vue'),
  },
];
