var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-5 mb-5"},[_c('a-row',[_c('a-col',[_c('a-row',{staticClass:"mb-3",attrs:{"type":"flex","justify":"space-between"}},[_c('h4',[_vm._v("Mahsulotlar ro'yxati")]),_c('router-link',{attrs:{"to":{ name: 'NewProduct' }}},[_c('a-button',{attrs:{"size":"large","type":"primary"}},[_c('a-icon',{attrs:{"type":"file-add"}}),_vm._v(" Mahsulot qo'shish ")],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"bordered":"","pagination":false,"loading":_vm.loading,"scroll":{ x: 500 }},scopedSlots:_vm._u([{key:"name",fn:function(text, record){return [_c('router-link',{staticClass:"text-dark font-weight-bold",attrs:{"to":{
              name: 'EditProduct',
              query: {
                page: _vm.$route.query.page ? _vm.$route.query.page : 1,
              },
              params: {
                productId: record.id,
              },
            }}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v(" "+_vm._s((record.id, text))+" ")],1)]}}])}),_c('div',{staticClass:"pageButtons"},[_c('base-pagination',{attrs:{"total":_vm.dataPaginate.count,"value":_vm.offset,"limit":_vm.limit,"navigate":true},on:{"change":_vm.paginateMethod}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }