import request, { formDataRequest } from '../../../api/fetchConfig';
export default {
  postSlider(_, data) {
    return formDataRequest('products/slider/', 'POST', data);
  },

  getAllSlider() {
    return request('products/slider/', 'GET');
  },
  getOneSlider(_, id) {
    return request(`products/slider/${id}`, 'GET');
  },

  deleteSlider(_, id) {
    return request(`products/slider/${id}`, 'DELETE');
  },
  editSlider(_, data) {
    return formDataRequest(`products/slider/${data.id}/`, 'PATCH', data.obj);
  },
};
