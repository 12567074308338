<template>
  <div class="container mt-5 mb-5">
    <a-row>
      <a-col>
        <a-row type="flex" justify="space-between" class="mb-3">
          <h4>Mahsulotlar ro'yxati</h4>
          <router-link :to="{ name: 'NewProduct' }">
            <a-button size="large" type="primary">
              <a-icon type="file-add" />
              Mahsulot qo'shish
            </a-button>
          </router-link>
        </a-row>

        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          :pagination="false"
          :loading="loading"
          :scroll="{ x: 500 }"
        >
          <template slot="name" slot-scope="text, record">
            <router-link
              class="text-dark font-weight-bold"
              :to="{
                name: 'EditProduct',
                query: {
                  page: $route.query.page ? $route.query.page : 1,
                },
                params: {
                  productId: record.id,
                },
              }"
            >
              <a-icon type="edit" /> {{ (record.id, text) }}
            </router-link>
          </template>
        </a-table>
        <div class="pageButtons">
          <base-pagination
            :total="dataPaginate.count"
            :value="offset"
            :limit="limit"
            :navigate="true"
            @change="paginateMethod"
          ></base-pagination>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
const columns = [
  {
    title: 'Mahsulot nomi',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Kategoriyasi',
    className: 'column-money',
    dataIndex: 'category',
  },
  {
    title: 'Diametr',
    dataIndex: 'diametr',
  },
];

const data = [
  {
    key: '1',
    name: 'John Brown',
    money: '￥300,000.00',
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    money: '￥1,256,000.00',
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    money: '￥120,000.00',
    address: 'Sidney No. 1 Lake Park',
  },
];
import { mapActions } from 'vuex';
import BasePagination from '../../components/BaseComponents/BasePagination.vue';
export default {
  components: {
    BasePagination,
  },
  data() {
    return {
      data,
      columns,
      loading: false,
      current: 1,
      next: '?limit=10&offset=1',
      previous: '?limit=10&offset=1',
      dataPaginate: [],
      offset: this.$route.query.page ? this.$route.query.page : 1,
      limit: 10,
    };
  },

  methods: {
    ...mapActions({
      getAllProducts: 'product/getPaginationProducts',
    }),
    async paginateMethod(pageOffset) {
      this.loading = true;
      this.offset = +pageOffset || 1;
      await this.$store
        .dispatch('product/getPaginationProducts', {
          limit: this.limit,
          offset: this.offset * this.limit - this.limit,
        })
        .then((res) => {
          this.dataPaginate = res;
          this.renderData(res.results);
        })
        .catch(() => {
          this.loading = false;
        });

      this.loading = false;
    },

    renderData(results) {
      const data = results.map((item) => {
        return {
          key: item.id,
          diametr: item.diametr,
          category: item.category_ru,
          name: item.name_ru,
          id: item.id,
        };
      });
      this.loading = false;

      this.data = data;
    },
  },

  created() {
    this.getAllProducts({
      limit: this.limit,
      offset: this.offset * this.limit - this.limit,
    }).then((res) => {
      this.dataPaginate = res;
      this.renderData(res.results);
    });
  },
};
</script>
<style>
th.column-money,
td.column-money {
  text-align: right !important;
}

.pageButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
