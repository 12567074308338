<template>
  <a-layout-sider v-model="collapsed" collapsible>
    <img src="../../assets/logoManokip.png" class="logo" alt="Manokip" />

    <a-menu
      theme="dark"
      :default-selected-keys="['1']"
      mode="inline"
      class="mt-2"
    >
      <a-menu-item key="1">
        <router-link :to="{ name: 'Categoriya' }">
          <a-icon type="folder" />
          <span>Kategoriya</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="8">
        <router-link to="/product">
          <a-icon type="diff" />
          <span>Mahsulotlar</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="9">
        <router-link :to="{ name: 'Slider' }">
          <a-icon type="scissor" />
          <span>Slider</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="10">
        <router-link :to="{ name: 'Document' }">
          <a-icon type="folder" />
          <span>Dokument</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="11">
        <router-link :to="{ name: 'Partner' }">
          <a-icon type="global" />
          <span>Hamkorlar</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="12">
        <router-link :to="{ name: 'Company' }">
          <a-icon type="home" />
          <span>Kompaniya</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="13">
        <router-link :to="{ name: 'SocialContact' }">
          <a-icon type="instagram" />
          <span>Ijtimoiy Tarmoqlar</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="14">
        <router-link :to="{ name: 'Contact' }">
          <a-icon type="message" />
          <span>Xabarlar</span>
        </router-link>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style scoped>
.logo {
  width: 90%;
  margin: 5px 5%;
}
</style>
