import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routesConfig';

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.VUE_APP_PREFIX,
  routes,
  mode: 'history',
});

router.beforeEach((to, _2, next) => {
  const token = sessionStorage.getItem('token');
  const meta = !!(to.meta && to.meta.auth);
  if (meta && token) {
    if (to.name === 'Login') {
      next({ name: 'Categoriya' });
      return;
    }

    next();
    return;
  }

  if (token) {
    if (to.name === 'Login') {
      next({ name: 'Categoriya' });
      return;
    }
  } else {
    if (to.name !== 'Login') {
      next({ name: 'Login' });
      return;
    }
  }

  next();
});

export default router;
