import request, { formDataRequest } from '../../../api/fetchConfig';
export default {
  postPartner(_, data) {
    return formDataRequest('documents/partners/', 'POST', data);
  },
  getOnePartner(_, id) {
    return request(`documents/partners/${id}`, 'GET');
  },
  patchPartner(_, data) {
    return formDataRequest(`documents/partners/${data.id}/`, 'PATCH', data.obj);
  },
  getAllPartners() {
    return request('documents/partners/', 'GET');
  },
  deletePartner(_, id) {
    return request(`documents/partners/${id}/`, 'DELETE');
  },
};
