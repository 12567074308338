<template>
  <div>
    <a-modal
      v-model="visible"
      :title="title"
      @ok="handleOk"
      @cancel="$emit('close', false)"
    >
      <slot></slot>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ['title'],
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    handleOk() {
      this.visible = !this.visible;
      this.$emit('close', false);
    },
  },
};
</script>
