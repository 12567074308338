import request from '../../../api/fetchConfig';
export default {
  getAllContacts() {
    return request('contact/contactus/', 'GET');
  },
  getOneContact(_, id) {
    return request(`contact/contactus/${id}`, 'GET');
  },
  postContact(_, data) {
    return request('contact/contactus/', 'POST', data);
  },
  deleteMessage(_, id) {
    return request(`contact/contactus/${id}`, 'DELETE');
  },
};
