<template>
  <section>
    <section>
      <a-layout style="min-height: 100vh">
        <the-side-bar></the-side-bar>
        <a-layout>
          <a-layout-header style="background: #002140FF; color:white;">
            <a-row type="flex" justify="end" v-if="valid">
              <a-col :span="4" class="position-relative">
                <a-avatar @click="tabHandler">
                  <a-icon slot="icon" type="user" />
                </a-avatar>
              </a-col>
            </a-row>
            <div class="row rounded bg-white chiqishCon" v-if="enter">
              <div class="col-4">
                <button class="btn btn-primary " @click="logOut">
                  Chiqish
                </button>
              </div>
            </div>
          </a-layout-header>
          <slot></slot>
        </a-layout>
      </a-layout>
    </section>
  </section>
</template>

<script>
import TheSideBar from '../SideBar/SideBar.vue';
export default {
  components: {
    TheSideBar,
  },
  data() {
    return {
      valid: sessionStorage.getItem('token'),
      enter: false,
    };
  },

  methods: {
    tabHandler() {
      this.enter = !this.enter;
    },
    logOut() {
      sessionStorage.removeItem('token');
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.chiqishCon {
  position: absolute;
  top: 12%;
  right: 13%;
  z-index: 99999;
}

@media (max-width: 576px) {
  .chiqishCon {
    top: 9%;
  }
}

@media (min-width: 900px) {
  .chiqishCon {
    top: 8%;
  }
}
</style>
