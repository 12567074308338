import request, { formDataRequest } from '../../../api/fetchConfig';
import axios from 'axios';
import rootUrl from '../../../config/url';

export default {
  createProduct(_, data) {
    return formDataRequest('products/', 'POST', data);
  },

  patchProduct(_, product) {
    return formDataRequest(`products/${product.id}/`, 'PATCH', product.data);
  },

  getAllProducts(_, query) {
    return request(`products/${query}`, 'GET')
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getPaginationProducts(commit, payload) {
    const TOKEN_PREFIX = 'Bearer';
    const TOKEN = sessionStorage.getItem('token');

    return axios
      .get(`${rootUrl}/products/`, {
        params: {
          limit: payload.limit,
          offset: payload.offset,
        },

        headers: {
          Authorization: TOKEN_PREFIX + ' ' + TOKEN,
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  getOneProduct(_, id) {
    return request(`products/${id}/`, 'GET');
  },

  deleteOneProduct(_, id) {
    request(`products/${id}/`, 'DELETE')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  },

  postExtraImg(_, data) {
    return formDataRequest('products/product-images/', 'POST', data);
  },
  deleteExtraImg(_, id) {
    return request(`products/product-images/${id}/`, 'DELETE');
  },
};
