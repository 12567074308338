import categoryActions from './categoryActions';
import categoryMutations from './categoryMutations';
import categoryGetter from './categoryGetter';

export default {
  namespaced: true,
  state() {
    return {
      categoryList: [],
      oneCategory: {},
    };
  },
  getters: categoryGetter,
  mutations: categoryMutations,
  actions: categoryActions,
};
